<div>
  <div class="mb-4 flex justify-between bg-slate-800 px-5 py-2 text-white">
    <div class="my-auto">
      <div
        class="mb-2 flex gap-1"
        *ngIf="account.companyName"
      >
        <img
          class="brand h-8 w-8 object-contain text-xl"
          data-test="logo-top"
          *ngIf="logo"
          [src]="logo"
        />
        <div class="text-3xl font-medium">
          {{ account.companyName }}
        </div>
      </div>
      <div class="font-medium leading-3">
        <span>Generated</span>
        <span
          style="color: #6db4fc"
          class="mx-1"
          >({{ currentDate | date : 'dd/MM/yyyy' }})</span
        >
        <span>for {{ user.email }}</span>
      </div>
    </div>
    <img
      class="brand w-32 cursor-pointer object-contain text-xl"
      data-test="logo-top"
      src="../../../assets/logo.png"
    />
  </div>
</div>
