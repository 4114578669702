import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router, RouterModule } from '@angular/router';
import { Observable } from 'rxjs';
import { LetDirective } from '@ngrx/component';

interface ExpansionPanelItem {
  id?: number;
  name?: string;
  color?: string;
  icon?: string;
  path?: string;
  dataTest?: string;
  flag?: Observable<boolean>;
  isReady?: Observable<boolean>;
  children?: ExpansionPanelItem[];
}

@Component({
  selector: 'codenteam-generic-expansion-panel',
  standalone: true,
  imports: [
    CommonModule,
    MatExpansionModule,
    MatListModule,
    MatIconModule,
    MatTooltipModule,
    RouterModule,
    LetDirective,
  ],
  templateUrl: './generic-expansion-panel.component.html',
  styleUrl: './generic-expansion-panel.component.scss',
})
export class GenericExpansionPanelComponent {
  @Input() toggleSide?: boolean;
  @Input() runId?: string;
  @Input() canEdit?: boolean;
  @Input() isProfileNotAssigned?: boolean;
  @Input() isAuthorNotAssigned?: boolean;

  @Input() headerName?: string;
  @Input() headerIcon?: string;

  @Input() list?: ExpansionPanelItem[];

  @Input() expanded?: boolean;
  @Input() show?: boolean;

  @Output() toggleEmitter = new EventEmitter<boolean>();
  @Output() expandedChange = new EventEmitter<boolean>();

  constructor(private router: Router) {}

  getExpandedState(): boolean {
    return this.toggleSide ? !!this.expanded : true;
  }

  onExpandedChange(isExpanded: boolean): void {
    if (this.toggleSide) {
      this.expanded = isExpanded;
      this.expandedChange.emit(isExpanded);
    }
  }

  navigateToUrl(url: string) {
    this.router.navigate([url]).then((res) => {
      this.toggleEmitter.emit(false);
    });
  }
}
