import { Injectable } from '@angular/core';
import { ApolloLink, NextLink, Observable, Operation } from '@apollo/client';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RequestQueueLink extends ApolloLink {
  private maxConcurrentRequests: number;
  private activeRequests;
  public change$ = new Subject<{
    done: number;
    all: number;
  }>();

  // Increment when a request is done
  public allDoneRequests = 0;

  // Increment when a request is made or queued
  public allRequests = 0;
  /**
   * Manages requests as functions with a maximum concurrency of n (For this comment assumed to be 4).
   * If there are already 4 active requests, incoming requests are added to a queue.
   * When a slot becomes available (i.e., one completes and there are fewer than 4 active requests), queued requests are processed.
   * Each request function executes independently and completes its task before freeing up space for the next request in the queue.
   */
  private queue: (() => void)[];
  constructor() {
    super();
    this.maxConcurrentRequests = 100000;
    this.activeRequests = 0;
    this.queue = [];
  }
  emit() {
    this.change$.next({
      done: this.allDoneRequests,
      all: this.allRequests,
    });
  }

  override request(operation: Operation, forward: NextLink) {
    return new Observable((observer) => {
      const executeRequest = () => {
        this.allRequests++;
        this.emit();
        // Increment active requests count
        this.activeRequests++;

        // Forward the request to the next link
        const subscription = forward(operation).subscribe({
          next: observer.next.bind(observer),
          error: observer.error.bind(observer),
          complete: () => {
            this.allDoneRequests++;
            this.emit();
            // Decrement active requests and check the queue
            this.activeRequests--;
            if (this.queue.length > 0) {
              // Start the next request from the queue
              const nextRequest = this.queue.shift();
              nextRequest();
            }
            observer.complete();
          },
        });

        // Ensure the request unsubscribes correctly
        return () => subscription.unsubscribe();
      };

      if (this.activeRequests < this.maxConcurrentRequests) {
        // Execute immediately if below limit
        executeRequest();
      } else {
        // Otherwise, queue it
        this.queue.push(executeRequest);
      }
    });
  }
}
