import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as d3 from 'd3';
import { C } from '@angular/cdk/keycodes';

@Component({
  selector: 'codenteam-spider-chart',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './spider-chart.component.html',
  styleUrl: './spider-chart.component.scss',
})
export class SpiderChartComponent implements OnInit {
  @ViewChild('spiderChart', { static: true }) spiderChart!: ElementRef;
  @ViewChild('graph', { static: true }) graph!: ElementRef;
  points: { x: number; y: number }[] = [];
  patchPoints1: [number, number][] = [];
  patchPoints2: [number, number][] = [];
  width: number;
  height: number;

  centerX: number;
  centerY: number;
  radius: number;

  @Input()
  teams: { name: string; color: string; rate1: number; rate2: number }[];

  public ngOnInit() {
    this.width = this.graph.nativeElement.offsetWidth;
    this.height = this.graph.nativeElement.offsetHeight;
    this.centerX = this.width / 2;
    this.centerY = this.height / 2.5;
    this.radius = Math.min(this.width, this.height) / 4;

    this.draw();
  }

  hexagonVertices() {
    const numberOfTeams = this.teams.length;
    let biggestRate = 0;
    for (let i = 0; i < numberOfTeams; i++) {
      if (
        this.teams[i].rate1 >= this.teams[i].rate2 &&
        this.teams[i].rate1 > biggestRate
      ) {
        biggestRate = this.teams[i].rate1;
      } else if (
        this.teams[i].rate2 >= this.teams[i].rate1 &&
        this.teams[i].rate2 > biggestRate
      ) {
        biggestRate = this.teams[i].rate2;
      }
    }

    const scaleMultiplier = (0.9 * this.radius) / biggestRate;
    const angle = Math.PI / (numberOfTeams / 2);
    for (let i = 0; i < numberOfTeams; i++) {
      this.points.push({
        x: this.radius * Math.sin(angle * i),
        y: this.radius * Math.cos(angle * i),
      });
      const scoreX1 =
        ((this.teams[i].rate1 * 80) / 100) *
          scaleMultiplier *
          Math.sin(angle * i) +
        this.centerX;
      const scoreY1 =
        ((this.teams[i].rate1 * 80) / 100) *
          scaleMultiplier *
          Math.cos(angle * i) +
        this.centerY;
      this.patchPoints1.push([scoreX1, scoreY1]);

      const scoreX2 =
        ((this.teams[i].rate2 * 80) / 100) *
          scaleMultiplier *
          Math.sin(angle * i) +
        this.centerX;
      const scoreY2 =
        ((this.teams[i].rate2 * 80) / 100) *
          scaleMultiplier *
          Math.cos(angle * i) +
        this.centerY;
      this.patchPoints2.push([scoreX2, scoreY2]);
    }
    this.patchPoints1.push(this.patchPoints1[0]);
    this.patchPoints2.push(this.patchPoints2[0]);
  }

  hexagonPoints(radius: number, cornerRadius: number) {
    const numberOfTeams = this.teams.length;

    const angle = Math.PI / (numberOfTeams / 2);

    const points = [];
    const roundedPoints = [];

    // Calculate the vertices of the hexagon
    for (let i = 0; i < numberOfTeams; i++) {
      points.push([radius * Math.sin(angle * i), radius * Math.cos(angle * i)]);
    }

    // Create extra points to simulate rounded corners
    for (let i = 0; i < numberOfTeams; i++) {
      const [x0, y0] = points[i];
      const [x1, y1] = points[(i + 1) % numberOfTeams];
      const dx = x1 - x0;
      const dy = y1 - y0;
      const length = Math.sqrt(dx * dx + dy * dy);
      const normX = dx / length;
      const normY = dy / length;

      // Start point on the edge
      roundedPoints.push([
        x0 + normX * cornerRadius,
        y0 + normY * cornerRadius,
      ]);

      // Arc point between corners
      roundedPoints.push([
        x1 - normX * cornerRadius,
        y1 - normY * cornerRadius,
      ]);
    }

    // Construct the points string for the polygon
    let pointsStr = `${roundedPoints[0][0]},${roundedPoints[0][1]}`;
    for (let i = 1; i < roundedPoints.length; i++) {
      pointsStr += ` ${roundedPoints[i][0]},${roundedPoints[i][1]}`;
    }

    // Close the polygon by returning to the start
    pointsStr += ` ${roundedPoints[0][0]},${roundedPoints[0][1]}`;

    return pointsStr;
  }

  draw() {
    // draw spider graph points
    this.hexagonVertices();
    const svg = d3
      .select(this.spiderChart.nativeElement)
      .attr('width', this.width)
      .attr('height', this.height);

    svg
      .append('polygon')
      .attr('points', this.hexagonPoints(this.radius, 1.5))
      .attr('transform', `translate(${this.centerX},${this.centerY})`)
      .style('stroke', '#94A3B8')
      .style('fill', '#94A3B84D')
      .style('stroke-width', '1.8px');

    this.points.forEach(({ x, y }) => {
      svg
        .append('line')
        .attr('x1', this.centerX)
        .attr('y1', this.centerY)
        .attr('x2', this.centerX + x)
        .attr('y2', this.centerY + y)
        .style('stroke', '#94A3B8')
        .style('stroke-width', '0.55px');
    });

    // put label on each point of the graph with circle colored by label color

    this.points.forEach((point, i) => {
      const numberOfTeams = this.teams.length;
      const angle = (i * Math.PI) / (numberOfTeams / 2);
      const offsetX = 15 * Math.sin(angle);
      const offsetY = 15 * Math.cos(angle);
      let circleOffsetX = 0;
      let textOffsetX = 0;
      const circleX = this.centerX + point.x + offsetX;
      const circleY = this.centerY + point.y + offsetY;

      if (circleX < this.centerX) {
        circleOffsetX = 45;
        textOffsetX = 35;
      } else if (circleX === this.centerX) {
        circleOffsetX = 20;
        textOffsetX = 10;
      } else {
        circleOffsetX = 0;
        textOffsetX = -10;
      }
      svg
        .append('circle')
        .attr('cx', circleX - circleOffsetX)
        .attr('cy', circleY)
        .attr('r', 4)
        .style('fill', this.teams[i].color)
        .style('stroke', this.teams[i].color);

      svg
        .append('text')
        .attr('x', circleX - textOffsetX)
        .attr('y', circleY)
        .attr('dominant-baseline', 'middle')
        .text(this.teams[i].name)
        .style('fill', '#000000') // Label color
        .style('font-size', '10px'); // Font size
    });

    const areaPath1 = d3.line()(this.patchPoints1);
    const areaPath2 = d3.line()(this.patchPoints2);

    svg
      .append('path')
      .attr('d', areaPath1)
      .attr('class', 'areaPath1')
      .style('fill', '#6DB4FC73') // Fill color
      .style('stroke', '#475569') // Stroke color
      .style('stroke-width', '2px'); // Stroke width

    svg
      .append('path')
      .attr('d', areaPath2)
      .attr('class', 'areaPath2')
      .style('fill', '#1E293B99') // Fill color
      .style('stroke', '#6DB4FC') // Stroke color
      .style('stroke-width', '2px'); // Stroke width

    const labelGroup = svg.append('g').attr('class', 'labels-group');

    // Label for Path 1
    labelGroup
      .append('circle')
      .attr('cx', this.centerX - 100)
      .attr('cy', this.centerY + this.radius * 2)
      .attr('r', 6)
      .style('fill', '#6DB4FC73') // Circle color
      .style('stroke', '#475569')
      .style('cursor', 'default')
      .on('mouseover', () => {
        svg.select('.areaPath2').style('opacity', 0.2); // Hide Path 2
      })
      .on('mouseout', () => {
        svg.select('.areaPath2').style('opacity', 1); // Show Path 2
      })
      .on('click', () => {
        const path1 = svg.select('.areaPath2');
        path1.style(
          'display',
          path1.style('display') === 'none' ? 'block' : 'none'
        );
      });

    // Circle stroke color

    labelGroup
      .append('text')
      .attr('x', this.centerX - 90)
      .attr('y', this.centerY + this.radius * 2)
      .text('This Month')
      .attr('dominant-baseline', 'middle')
      .attr('id', 'labelPath1')
      .style('cursor', 'default')
      .style('font-size', '12px') // Font size
      .on('mouseover', () => {
        svg.select('.areaPath2').style('opacity', 0.2); // Hide Path 2
      })
      .on('mouseout', () => {
        svg.select('.areaPath2').style('opacity', 1); // Show Path 2
      })
      .on('click', () => {
        const path1 = svg.select('.areaPath2');
        path1.style(
          'display',
          path1.style('display') === 'none' ? 'block' : 'none'
        );
      });

    // Label for Path 2
    labelGroup
      .append('circle')
      .attr('cx', this.centerX + 18)
      .attr('cy', this.centerY + this.radius * 2)
      .attr('r', 6)
      .style('fill', '#1E293B99')
      .style('stroke', '#6DB4FC')
      .style('cursor', 'default')
      .on('mouseover', () => {
        svg.select('.areaPath1').style('opacity', 0.2);
      })
      .on('mouseout', () => {
        svg.select('.areaPath1').style('opacity', 1);
      })
      .on('click', () => {
        const path2 = svg.select('.areaPath1');
        path2.style(
          'display',
          path2.style('display') === 'none' ? 'block' : 'none'
        );
      });

    labelGroup
      .append('text')
      .attr('x', this.centerX + 30)
      .attr('y', this.centerY + this.radius * 2)
      .text('Last Month')
      .attr('dominant-baseline', 'middle')
      .attr('id', 'labelPath2')
      .style('cursor', 'default')
      .style('font-size', '12px') // Font size
      .on('mouseover', () => {
        svg.select('.areaPath1').style('opacity', 0.2); // Hide Path 1
      })
      .on('mouseout', () => {
        svg.select('.areaPath1').style('opacity', 1); // Show Path 1
      })
      .on('click', () => {
        const path2 = svg.select('.areaPath1');
        path2.style(
          'display',
          path2.style('display') === 'none' ? 'block' : 'none'
        );
      });
  }
}
