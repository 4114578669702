export enum ScanningCodeVulnTypes {
  ERROR = 'error',
  CRITICAL = 'critical',
  INFO = 'info',
}

export const scanningCodeSeverityOrder: Record<ScanningCodeVulnTypes, number> =
  {
    [ScanningCodeVulnTypes.CRITICAL]: 0,
    [ScanningCodeVulnTypes.ERROR]: 1,
    [ScanningCodeVulnTypes.INFO]: 2,
  };
