import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { combineLatest, map, Observable, of, switchMap } from 'rxjs';
import { SafeHtml } from '@angular/platform-browser';
import { LetDirective } from '@ngrx/component';
import { ReportHeaderComponent } from '@codenteam/ui/report/report-header/report-header.component';
import { SpiderChartComponent } from '@codenteam/ui/spider-chart/spider-chart.component';
import { NameValue } from '@codenteam/ui/types/name-value.type';
import { ApiService } from '../../core/api.service';
import { LinesService } from '../../core/lines.service';
import { Utils } from '../../core/utils';
import { PieGraphComponent } from '@codenteam/ui/pie-graph/pie-graph.component';
import { LicensesScanReportComponent } from '../licenses-scan-report/licenses-scan-report.component';
import { Level, RiskTag, ScanStatus } from '@codenteam/portal/graphql';
import { ReportService } from '../report.service';
import { LicenseAndVulnerabilityService } from '../../core/license-and-vulnerability.service';
import { ReportPausedAnalysisMessageComponent } from '@codenteam/ui/report-paused-analysis-message/report-paused-analysis-message.component';

export interface PeriodicElement {
  metric: string | SafeHtml;
  now: number;
  was: number | string;
  notes: string | SafeHtml;
}
@Component({
  selector: 'codenteam-company-analysis-report',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatTableModule,
    SpiderChartComponent,
    LetDirective,
    ReportHeaderComponent,
    PieGraphComponent,
    LicensesScanReportComponent,
    ReportPausedAnalysisMessageComponent,
  ],
  templateUrl: './company-analysis-report.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class CompanyAnalysisReportComponent implements OnInit {
  displayedColumns: string[] = ['metric', 'now', 'was', 'notes'];
  dataSource: PeriodicElement[] = [];
  teamsThisMonth: NameValue[];
  teamsPreviousMonth: NameValue[];
  showCodenteamLogoIfNoCompanyLogo = true;

  @Input()
  runId: string;

  @Output()
  completed = new EventEmitter<boolean>();

  runStatus: string;

  report$: Observable<any>;
  currentDate: string = this.reportService.getDate().currentDate;
  lastMonthDate: string = this.reportService.getDate().lastMonthDate;
  numberOfTeams: number;
  numberOfExEmployees: number;
  numberOfCurrentEmployees: number;
  spiderTeams: { name: string; color: string; rate1: number; rate2: number }[];
  dataSource$: Observable<PeriodicElement[]>;
  numberOfTotalRisks = 0;
  numberOfCriticalRisks = 0;
  numberOfErrorRisks = 0;
  numberOfWarningRisks = 0;
  numberOfRisksLastMonth = 0;
  account$ = this.apiService.getAccountData();
  userInfo$ = this.apiService.getUserInfo();
  linesPerTeam$: Observable<NameValue[]>;
  licenseScanStatus$: Observable<ScanStatus>;
  scanStatus = ScanStatus;
  date =
    new Date(new Date().setMonth(new Date().getMonth() - 1)).getTime() / 1000;
  haveValue = (object: NameValue[]): boolean =>
    this.reportService.objectHasValue(object);

  table$: Observable<
    {
      metric: string | SafeHtml;
      now: number;
      was: string | number;
      notes: SafeHtml;
    }[]
  >;

  @Input()
  logoPreview$: Observable<string | ArrayBuffer>;

  constructor(
    private apiService: ApiService,
    private linesService: LinesService,
    private reportService: ReportService,
    private licenseAndVulnerabilityService: LicenseAndVulnerabilityService
  ) {}

  fetchAll() {
    const linesPerTeam$ = this.linesService.contributionsPer$({
      range$: of({ start: null, end: null }),
      per: 'team',
      runId$: of(this.runId),
    });

    const linesPerProfilesLasMonth$ = this.linesService.contributionsPer$({
      range$: of({ start: this.date, end: new Date().getTime() / 1000 }),
      per: 'profile',
      runId$: of(this.runId),
    });

    const exEmployeesInCompany$ = this.apiService.getExEmployeesInRun(
      this.runId
    );
    const runRisks$ = this.apiService.getRunCodeRisksForReport(this.runId);

    this.licenseScanStatus$ =
      this.licenseAndVulnerabilityService.getLicAndVulnScanStatus(this.runId);
    this.table$ = combineLatest([
      this.apiService.getRun(this.runId),
      this.apiService.getReportClosestTo30DaysAgo(this.runId),
      this.apiService.countDirectories(this.runId),
      this.apiService.countFiles(this.runId),
      this.linesService.getHighestContributingTeam$(of(this.runId)),
      this.linesService.getHighestContributingProfile$(of(this.runId)),
      this.linesService.getExContribution(of(this.runId)).exContribution$,
      this.apiService.countFilesBeforeLast30Days(this.runId),
      this.apiService.countDirectoriesBeforeLast30Days(this.runId),
      this.apiService.getProfilesForReport(this.runId),
      this.apiService.getTeamsWithProfilesForReport(this.runId),
      linesPerTeam$,
      linesPerProfilesLasMonth$,
      exEmployeesInCompany$,
      runRisks$,
      this.apiService.getNumberOfModules(this.runId),
    ]).pipe(
      map(
        ([
          run,
          report,
          directories,
          files,
          highestContributingTeam,
          highestContributingProfile,
          exContribution,
          numberOfFilesBeforeLast30Days,
          numberOfDirectoriesBeforeLast30Days,
          profiles,
          teams,
          linesPerTeam,
          linesPerProfilesLasMonth,
          exEmployeesInCompany,
          runRisks,
          numberOfAllModules,
        ]) => {
          this.runStatus = run.status;
          //get number of ex-employees in company
          this.numberOfExEmployees = exEmployeesInCompany.length;

          //get number of current-employees in company
          this.numberOfCurrentEmployees =
            profiles.length - exEmployeesInCompany.length;

          //get exContribution (now) value
          const exContributionValue = exContribution[1]
            ? exContribution[1].value
            : 0;

          //get exContribution (was) value

          const exContributionBefore30Days = Utils.resolveHistoricalValue(
            report,
            'ex',
            'total',
            1
          ).value;

          //get the total number of lines (now) value

          const numberOfLines = exContribution.reduce(
            (sum: number, ex: any) => sum + ex.value,
            0
          );

          //get the total number of lines (was) value

          const numberOfLinesBefore30Days = report.code.total.profile.reduce(
            (sum: number, profile: any) => sum + profile.value,
            0
          );

          //fill spiderTeams array for spider chart if number of team is more than 2

          if (teams.length > 2) {
            this.spiderTeams = teams.map((team) => {
              const teamLinesBeforeMonth = Utils.resolveHistoricalValue(
                report,
                'team',
                'total',
                team.id
              ).value;
              const teamLines =
                linesPerTeam.find((item) => item.id === team.id)?.value ?? 0;
              const rateThisMonth = parseFloat(
                ((teamLines / numberOfLines) * 100).toFixed()
              );
              const rateLastMonth = parseFloat(
                (
                  (teamLinesBeforeMonth / numberOfLinesBefore30Days) *
                  100
                ).toFixed()
              );
              return {
                name: team.name,
                color: team.color,
                rate1: rateThisMonth,
                rate2: rateLastMonth,
              };
            });
          } else {
            //fill teamsThisMonth and teamsPreviousMonth arrays for pie chart if number of team is equal or less than 2

            this.teamsThisMonth = linesPerTeam.map((team) => {
              let rateNow = 0;
              rateNow = parseFloat(
                ((team.value / numberOfLines) * 100).toFixed()
              );
              return {
                id: team.id,
                name: team.name,
                value: rateNow,
                color: team.color,
              } as NameValue;
            });
            this.teamsPreviousMonth = teams.map((team) => {
              const teamLastMonth = Utils.resolveHistoricalValue(
                report,
                'team',
                'total',
                team.id
              );
              let rate = 0;
              if (numberOfLinesBefore30Days > 0) {
                rate = parseFloat(
                  (
                    (teamLastMonth.value / numberOfLinesBefore30Days) *
                    100
                  ).toFixed()
                );
              }

              return {
                id: team.id,
                name: team.name,
                value: rate,
                color: team.color,
              } as NameValue;
            });
          }
          //get number of teams (now) value

          this.numberOfTeams = teams.length;

          //get number of teams (was) value

          const numberOfTeamsBefore30Days = report.code.total.team.length;

          //get number of profiles (was) value

          const numberOfProfilesBefore30Days = report.code.total.profile.length;

          //get Average Contribution per Team (now) value

          const averageContributionPerTeam = Math.floor(
            numberOfLines / this.numberOfTeams
          );

          //get Average Contribution per Team (was) value

          const averageContributionPerTeamBefore30Days = Math.floor(
            numberOfLinesBefore30Days / numberOfTeamsBefore30Days
          );

          //get Average Contribution per Profile (now) value

          const averageContributionPerProfile = Math.floor(
            numberOfLines / profiles.length
          );

          //get Average Contribution per Profile (was) value

          const averageContributionPerProfileBefore30Days = Math.floor(
            numberOfLinesBefore30Days / numberOfProfilesBefore30Days
          );

          //get highest contributing team (was) value

          const highestContributingTeamBeforeLast30Days =
            report.code.total.team.reduce((max, item) =>
              item.value > max.value ? item : max
            );

          //get highest contributing profile (was) value

          const highestContributingProfileBeforeLast30Days =
            this.reportService.getHighestObjectWorkFactor(
              report.code.month.profile
            );

          const contributionForHighestContributingProfile =
            highestContributingProfileBeforeLast30Days.value;

          //calculate average work factor (now) value

          const averageWorkFactor =
            profiles.reduce((sum: number, profile: any) => {
              const currentProfile = linesPerProfilesLasMonth.find(
                (prof) => prof.id === profile.id
              );
              const workFactor =
                profile.hoursPerWeek && profile.costPerHour && currentProfile
                  ? currentProfile.value /
                    (profile.hoursPerWeek * 4 * profile.costPerHour)
                  : 0;
              return sum + workFactor;
            }, 0) / profiles.length;

          //calculate average work factor (was) value

          const averageWorkFactorBeforeLast30Days =
            report.code.month.profile.reduce((sum: number, profile: any) => {
              const workFactor = profile.workFactor;
              return sum + workFactor;
            }, 0) / profiles.length;

          //calculate highest team work factor (now) value
          const highestTeamForWorkFactor = teams.reduce(
            (highest, team) => {
              let teamLines = 0;
              let costOfHoursPerMonth = 0;

              for (const profile of team.profiles) {
                const curProfile = linesPerProfilesLasMonth.find(
                  (item) => item.id === profile.id
                );
                teamLines += curProfile ? curProfile.value : 0;

                if (profile.hoursPerWeek && profile.costPerHour) {
                  costOfHoursPerMonth +=
                    profile.hoursPerWeek * 4 * profile.costPerHour;
                }
              }
              const workFactor =
                costOfHoursPerMonth > 0 && teamLines > 0
                  ? teamLines / costOfHoursPerMonth
                  : 0;
              return workFactor > highest.workFactor
                ? { team, workFactor }
                : highest;
            },
            { team: teams[0], workFactor: 0 }
          );

          //calculate highest team work factor (was) value
          const highestTeamForWorkFactorBeforeLast30Days =
            this.reportService.getHighestObjectWorkFactor(
              report.code.month.team
            );

          //calculate highest profile work factor (now) value

          const WorkFactorForProfiles = linesPerProfilesLasMonth.reduce(
            (highest, profile) => {
              const currentProfile = profiles.find(
                (prof) => prof.id === profile.id
              );
              const workFactor =
                currentProfile.hoursPerWeek && currentProfile.costPerHour
                  ? profile.value /
                    (currentProfile.hoursPerWeek *
                      4 *
                      currentProfile.costPerHour)
                  : 0;

              if (workFactor > highest.workFactor) {
                return { profile, workFactor };
              }

              return highest;
            },
            { profile: linesPerProfilesLasMonth[0], workFactor: 0 }
          );

          //calculate highest profile work factor (was) value

          const HighestProfileInWorkFactorBeforeLast30Days =
            this.reportService.getHighestObjectWorkFactor(
              report.code.month.profile
            );

          //add risk if ex employees contribution percentage is more than 50% (last month)

          const exOwnerShipPercentageLastMonth = Math.floor(
            Math.min(
              (exContributionBefore30Days * 100) / numberOfLinesBefore30Days,
              100
            )
          );
          if (exOwnerShipPercentageLastMonth >= 50) {
            this.numberOfRisksLastMonth++;
          }

          //add risk if current employee contribution percentage is more than 50% (last Month)

          report.code.total.profile.forEach((profile) => {
            const profileContributionPercentageLastMonth = Math.floor(
              Math.min((profile.value * 100) / numberOfLinesBefore30Days, 100)
            );
            if (profileContributionPercentageLastMonth >= 50) {
              this.numberOfRisksLastMonth++;
            }
          });

          // Aggregate risk counts by tag while categorizing risks by severity
          const riskCountsByTag = runRisks.reduce((acc, risk) => {
            // Update total risk count
            this.numberOfTotalRisks += risk.count;

            // Count risks based on severity level
            if (risk.level === Level.Critical)
              this.numberOfCriticalRisks += risk.count;
            else if (risk.level === Level.Error)
              this.numberOfErrorRisks += risk.count;
            else if (risk.level === Level.Warning)
              this.numberOfWarningRisks += risk.count;

            // Count risks based on their tag
            acc[risk.riskTag] = (acc[risk.riskTag] || 0) + (risk.count ?? 1);

            return acc;
          }, {} as Record<RiskTag, number>);

          // Retrieve specific risk counts based on their tags
          const numberOfOwnedModulesByExEmployees =
            riskCountsByTag[RiskTag.DirectoryExEmployeeHighOwnership] || 0;

          const modulesWithNoClearOwnership =
            riskCountsByTag[RiskTag.DirectoryNoClearOwnership] || 0;

          const numberOfOwnedModulesByCurrentEmployees =
            riskCountsByTag[RiskTag.DirectoryCurrentEmployeeHighOwnership] || 0;

          // Retrieve specific risk descriptions
          const profileOwnCompanyCode =
            runRisks.find(
              (risk) =>
                risk.riskTag === RiskTag.AllCodeCurrentEmployeeHighOwnership
            )?.description ?? null;

          const exEmployeeHighContributionOverAllCode =
            runRisks.find(
              (risk) => risk.riskTag === RiskTag.AllCodeExEmployeeHighOwnership
            )?.description ?? null;

          // Calculate number of modules owned by teams (excluding those with no clear ownership)
          const numberOfOwnedModulesByTeams =
            numberOfAllModules - modulesWithNoClearOwnership;

          // Generate note
          let exContributionNote = '';
          if (exEmployeeHighContributionOverAllCode) {
            exContributionNote = `<div>
      <span class="text-red-800">High-risk </span>${exEmployeeHighContributionOverAllCode}
    </div>`;
          }
          const numberOfLinesNote = this.reportService.calculatePercentage(
            numberOfLines,
            numberOfLinesBefore30Days,
            false
          );

          const averageContributionPerTeamNote =
            this.reportService.calculatePercentage(
              averageContributionPerTeam,
              averageContributionPerTeamBefore30Days,
              false
            );

          const averageContributionPerProfileNote =
            this.reportService.calculatePercentage(
              averageContributionPerProfile,
              averageContributionPerProfileBefore30Days,
              false
            );

          const numberOfFilesNote = this.reportService.calculatePercentage(
            files,
            numberOfFilesBeforeLast30Days,
            false
          );

          const numberOfDirectoriesNote =
            this.reportService.calculatePercentage(
              directories,
              numberOfDirectoriesBeforeLast30Days,
              false
            );

          const highestContributingTeamNote = this.highestContributingNoteFn(
            highestContributingTeam,
            highestContributingTeamBeforeLast30Days
          );

          const highestContributingProfileNote =
            this.reportService.highestContributingProfileNoteFn(
              highestContributingProfile,
              highestContributingProfileBeforeLast30Days,
              profileOwnCompanyCode
            );

          const averageWorkFactorNote = this.reportService.calculatePercentage(
            averageWorkFactor,
            averageWorkFactorBeforeLast30Days,
            false
          );

          const highestProfileInWorkFactorNote =
            this.reportService.calculatePercentage(
              WorkFactorForProfiles.workFactor,
              HighestProfileInWorkFactorBeforeLast30Days.workFactor,
              false
            );

          const highestTeamInWorkFactorNote =
            this.reportService.calculatePercentage(
              highestTeamForWorkFactor.workFactor,
              highestTeamForWorkFactorBeforeLast30Days.workFactor,
              false
            );
          this.completed.emit(true);
          return [
            {
              metric: 'Number of Directories',
              now: directories,
              was: numberOfDirectoriesBeforeLast30Days,
              notes: numberOfDirectoriesNote,
            },
            {
              metric: 'Number of Files',
              now: files,
              was: numberOfFilesBeforeLast30Days,
              notes: numberOfFilesNote,
            },
            {
              metric: 'Number of Lines',
              now: numberOfLines,
              was: numberOfLinesBefore30Days,
              notes: numberOfLinesNote,
            },
            {
              metric: 'Number of Teams',
              now: this.numberOfTeams,
              was: numberOfTeamsBefore30Days,
              notes: '',
            },
            {
              metric: 'Number of Developers',
              now: profiles.length,
              was: numberOfProfilesBefore30Days,
              notes: '',
            },
            {
              metric: 'Average Contribution per Team',
              now: averageContributionPerTeam,
              was: averageContributionPerTeamBefore30Days,
              notes: averageContributionPerTeamNote,
            },
            {
              metric: 'Average Contribution per Dev',
              now: averageContributionPerProfile,
              was: averageContributionPerProfileBefore30Days,
              notes: averageContributionPerProfileNote,
            },
            {
              metric: this.reportService.sanitizeHtml(
                `Highest Contributing Team : <span style="color: ${highestContributingTeam.color};">${highestContributingTeam.name}</span>`
              ),
              now: highestContributingTeam.value,
              was: highestContributingTeamBeforeLast30Days.value,
              notes: highestContributingTeamNote,
            },
            {
              metric: this.reportService.sanitizeHtml(
                `Highest Contributing Developer : <span style="color: ${highestContributingProfile.color};">${highestContributingProfile.name}</span>`
              ),
              now: highestContributingProfile.value,
              was: contributionForHighestContributingProfile,
              notes: highestContributingProfileNote,
            },
            {
              metric: 'Ex-Employee Ownership',
              now: exContributionValue,
              was: exContributionBefore30Days,
              notes: exContributionNote,
            },
            {
              metric: 'Modules owned By Ex-Employees',
              now: numberOfOwnedModulesByExEmployees,
              was: 0,
              notes: this.reportService.ownedModulesNoteFn(
                'ex-Employee',
                numberOfOwnedModulesByExEmployees,
                numberOfAllModules
              ),
            },
            {
              metric: 'Modules owned by a single developer',
              now: numberOfOwnedModulesByCurrentEmployees,
              was: 0,
              notes: this.reportService.ownedModulesNoteFn(
                'developer',
                numberOfOwnedModulesByCurrentEmployees,
                numberOfAllModules
              ),
            },
            {
              metric: 'Modules with no clear ownership',
              now: modulesWithNoClearOwnership,
              was: 0,
              notes: this.reportService.ownedModulesNoteFn(
                '',
                modulesWithNoClearOwnership,
                numberOfAllModules
              ),
            },
            {
              metric: 'Modules properly owned by single team',
              now: numberOfOwnedModulesByTeams,
              was: 0,
              notes: '',
            },
            // {
            //   metric: Code Dilution %,
            //   now: parseFloat(
            //     ((codeDilution.value / codeDilution.total) * 100).toFixed(2)
            //   ),
            //   was: '-',
            //   notes: '',
            // },
            // {
            //   metric: this.reportService.sanitizeHtml(
            //     Highest Team Code Dilution : <span style="color: ${highestTeamDilution.maxTeam.color};">${highestTeamDilution.maxTeam.name}</span>
            //   ),
            //   now: parseFloat(
            //     (
            //       (highestTeamDilution.maxDilution /
            //         highestTeamDilution.maxTotal) *
            //       100
            //     ).toFixed(2)
            //   ),
            //   was: '-',
            //   notes: '',
            // },
            // {
            //   metric: this.reportService.sanitizeHtml(
            //     Highest Developer Code Dilution : <span style="color: ${highestProfileDilution.maxProfile.color};">${highestProfileDilution.maxProfile.name}</span>
            //   ),
            //   now: parseFloat(
            //     (
            //       (highestProfileDilution.maxDilution /
            //         highestProfileDilution.maxTotal) *
            //       100
            //     ).toFixed(2)
            //   ),
            //   was: '-',
            //   notes: '',
            // },
            {
              metric: 'Average Work Factor',
              now: parseFloat(averageWorkFactor.toFixed(2)),
              was: parseFloat(averageWorkFactorBeforeLast30Days.toFixed(2)),
              notes: averageWorkFactorNote,
            },
            {
              metric: this.reportService.sanitizeHtml(
                `Highest Team in Work Factor : <span style="color: ${
                  highestTeamForWorkFactor.team.color
                }">${
                  highestTeamForWorkFactor.workFactor > 0
                    ? highestTeamForWorkFactor.team.name
                    : ''
                }</span>`
              ),
              now: parseFloat(highestTeamForWorkFactor.workFactor.toFixed(2)),
              was: parseFloat(
                highestTeamForWorkFactorBeforeLast30Days?.workFactor?.toFixed(2)
              ),
              notes: highestTeamInWorkFactorNote,
            },
            ...(WorkFactorForProfiles.profile
              ? [
                  {
                    metric: this.reportService.sanitizeHtml(
                      `Highest Developer in Work Factor : <span style="color: ${
                        WorkFactorForProfiles.profile.color
                      }">${
                        WorkFactorForProfiles.workFactor > 0
                          ? WorkFactorForProfiles.profile.name
                          : ''
                      }</span>`
                    ),
                    now: parseFloat(
                      WorkFactorForProfiles.workFactor.toFixed(2)
                    ),
                    was: parseFloat(
                      HighestProfileInWorkFactorBeforeLast30Days.workFactor.toFixed(
                        2
                      )
                    ),
                    notes: highestProfileInWorkFactorNote,
                  },
                ]
              : []),
          ];
        }
      )
    );
  }

  ngOnInit(): void {
    this.fetchAll();
  }

  highestContributingNoteFn(
    highestContributing: any,
    highestContributingBeforeLast30Days: any
  ) {
    if (highestContributing.id !== highestContributingBeforeLast30Days.id) {
      return this.reportService.sanitizeHtml(
        ` <div><span style="color: ${highestContributing.color}">${highestContributing.name}</span> Overtook <span style="color: ${highestContributingBeforeLast30Days.color}">${highestContributingBeforeLast30Days.name}</span></div>`
      );
    } else {
      return '';
    }
  }
}
