import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'codenteam-report-paused-analysis-message',
  standalone: true,
  imports: [CommonModule, MatIconModule],
  templateUrl: './report-paused-analysis-message.component.html',
  styleUrl: './report-paused-analysis-message.component.scss',
})
export class ReportPausedAnalysisMessageComponent {}
