<div
  class="mb-4 flex items-center justify-between rounded-md bg-slate-200 p-3 text-black"
>
  <mat-icon class="!w-20">error_outline</mat-icon>
  <p class="ml-2 leading-4">
    This is incomplete analysis due to quota limitations. Accordingly, it may
    miss important data for your analysis. To complete the analysis, please
    upgrade your plan and resume the analysis.
  </p>
</div>
