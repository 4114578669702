import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { ApiService } from '../core/api.service';
import { AccountPackage, User } from '@codenteam/portal/graphql';
import { MatDialog } from '@angular/material/dialog';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { catchError } from 'rxjs/operators';
import { UserAccessibilityStatus } from '../app.module';
import { EMPTY } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { PopupModelComponent } from '../wizard/popup-model/popup-model.component';
import { CommonModule } from '@angular/common';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatMenuModule } from '@angular/material/menu';
import { AccountDataDto } from './account-data.dto';
import { HiringCoreService } from '../hiring/hiring-core/hiring-core.service';
import { LetDirective } from '@ngrx/component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDividerModule } from '@angular/material/divider';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { SharedModule } from '../shared.module';
import { AnalyticsService } from '../core/analytics/analytics.service.abstract';

@Component({
  selector: 'codenteam-settings',
  templateUrl: './settings.component.html',
  standalone: true,
  imports: [
    SharedModule,
    CommonModule,
    MatProgressBarModule,
    ReactiveFormsModule,
    MatMenuModule,
    MatCheckboxModule,
    LetDirective,
    MatTooltipModule,
    MatDividerModule,
  ],
})
export class SettingsComponent {
  users: User[];
  accountData: AccountDataDto;
  isCurrentUserAdmin: boolean;
  currentUserId: number;
  subscriptionData: AccountPackage;
  currentAliasesCount: number;
  currentPentestsCount: number;
  daysRemaining: number;
  trialDaysRemaining = 0;
  isFormFieldChanged = false;
  isEmailInAnotherAccount: boolean;

  @ViewChild('fileInput') fileInput: ElementRef;
  emailForm = new FormGroup({
    email: new FormControl<string | null>(null, [
      Validators.required,
      Validators.pattern('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$'),
    ]),
  });

  trimmedLengthValidator(control: AbstractControl) {
    const value = control.value?.trim() || '';
    return value.length > 0 ? null : { trimmedLength: true };
  }

  accountDataForm = new FormGroup({
    companyName: new FormControl<string>(null, [
      Validators.required,
      this.trimmedLengthValidator,
    ]),
    companyDescription: new FormControl<string>(null, []),
    location: new FormControl<string>(null, []),
    link: new FormControl<string>(null, []),
    logo: new FormControl<string | null>(null, []),
    accountSettingsDataForm: new FormGroup({
      allowAutomatedUpdates: new FormControl<Date | null>(null, []),
    }),
  });
  logoPreview: string | ArrayBuffer = null;

  constructor(
    private apiService: ApiService,
    private dialogRef: MatDialog,
    private changeDetectorRef: ChangeDetectorRef,
    private titleService: Title,
    private hiringCoreService: HiringCoreService,
    private mixpanelService: AnalyticsService
  ) {}
  ngOnInit() {
    this.getAccountUsers();
    this.getAccountData();
    this.getCurrentUserData();
    this.getSubscriptionData();
    this.getCurrentAccountAliasesCount();
    this.getCurrentAccountPentestsCount();
    this.setTitle();
    this.getCompanyLogo();
  }

  getCompanyLogo() {
    if (!this.logoPreview) {
      this.hiringCoreService.getCompanyLogo().subscribe((logo) => {
        this.logoPreview = logo;
        this.changeDetectorRef.detectChanges();
      });
    }
  }

  checkIfFormChanged() {
    if (this.accountDataForm.touched === true) {
      this.isFormFieldChanged = true;
    } else {
      this.isFormFieldChanged = false;
    }
  }

  getLogoPreview(file: File) {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = () => {
      this.logoPreview = reader.result;
      this.changeDetectorRef.detectChanges();
    };
  }

  browseForLogo() {
    this.fileInput.nativeElement.click();
  }

  handleLogoUpload(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const file = inputElement.files?.[0];
    this.getLogoPreview(file);
    this.hiringCoreService.uploadLogo(file).subscribe(() => {
      this.changeDetectorRef.detectChanges();
    });
  }

  getAccountData() {
    this.apiService.getAccountData().subscribe((account) => {
      this.accountData = account;
      this.accountDataForm.patchValue({
        companyDescription: account.companyDescription,
        companyName: account.companyName,
        location: account.location,
        logo: account.logo,
        link: account.website,
      });
    });
    this.apiService.getAccountSettingsData().subscribe((accountSetting) => {
      this.accountDataForm.get('accountSettingsDataForm').patchValue({
        allowAutomatedUpdates: accountSetting.allowAutomatedUpdates,
      });
    });
  }

  updateAccountData() {
    this.checkIfFormChanged();
    if (this.isFormFieldChanged == true) {
      this.apiService
        .updateAccountData({
          companyName: this.accountDataForm.controls.companyName.value,
          companyDescription:
            this.accountDataForm.controls.companyDescription.value,
          website: this.accountDataForm.controls.link.value,
          location: this.accountDataForm.controls.location.value,
          logo: this.accountDataForm.controls.logo.value,
          allowAutomatedUpdates: this.accountDataForm.get(
            'accountSettingsDataForm.allowAutomatedUpdates'
          ).value,
        })
        .subscribe((res) => {
          this.getAccountData();
        });
    }
  }

  updateAllowAutomatedUpdates(event: any) {
    const allowAutomatedUpdatesControl = this.accountDataForm.get(
      'accountSettingsDataForm.allowAutomatedUpdates'
    ) as FormControl<Date | null>;
    const isChecked = event.checked;
    const value = isChecked ? new Date() : null;
    allowAutomatedUpdatesControl.setValue(value);
  }

  getCurrentAccountAliasesCount() {
    this.apiService.getCurrentAccountAliases().subscribe((aliases) => {
      this.currentAliasesCount = aliases;
      this.changeDetectorRef.detectChanges();
    });
  }

  getCurrentAccountPentestsCount() {
    this.apiService.countAccountPentestsThisMonth().subscribe((pentests) => {
      this.currentPentestsCount = pentests ?? 0;
      this.changeDetectorRef.detectChanges();
    });
  }

  getAccountUsers() {
    this.apiService.getAccountUsers().subscribe((users) => {
      this.users = users as User[];
      this.changeDetectorRef.detectChanges();
    });
  }

  getSubscriptionData() {
    this.apiService.getSubscriptionData().subscribe((sub) => {
      this.subscriptionData = sub;
      this.daysRemaining = this.calculateDaysRemaining(
        +this.subscriptionData?.expirationDate
      );
      if (this.subscriptionData?.trialExpirationDate) {
        this.trialDaysRemaining = this.calculateDaysRemaining(
          +this.subscriptionData?.trialExpirationDate
        );
        this.changeDetectorRef.detectChanges();
      }
    });
  }

  isBetweenSubscriptionDates(): boolean {
    if (
      !this.subscriptionData ||
      !this.subscriptionData.expirationDate ||
      !this.subscriptionData.trialExpirationDate
    ) {
      return false;
    }

    const currentDate = new Date();
    const expirationDate = new Date(this.subscriptionData.expirationDate);
    const trialExpirationDate = new Date(
      this.subscriptionData.trialExpirationDate
    );

    return currentDate <= expirationDate && currentDate >= trialExpirationDate;
  }

  calculateDaysRemaining(date: number): number {
    const currentDate = new Date();
    const expirationDate = new Date(date);
    const timeDifference = expirationDate.getTime() - currentDate.getTime();
    const daysRemaining = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
    return Math.max(0, daysRemaining);
  }

  getCurrentUserData() {
    this.apiService.getUserInfo().subscribe((user) => {
      this.isCurrentUserAdmin = user.isAdmin;
      if (!this.isCurrentUserAdmin) {
        this.accountDataForm.disable();
      }
      this.currentUserId = user.id;
      this.changeDetectorRef.detectChanges();
    });
  }

  removeUserFromAccount(userId: number) {
    return this.apiService
      .removeUserFromAccount(userId)
      .subscribe((res) => this.getAccountUsers());
  }

  addAdmin(user: User) {
    return this.apiService.addAdmin(user.id).subscribe((res) => {
      user.isAdmin = true;
      this.getAccountUsers();
    });
  }

  removeAdmin(UserId: number) {
    return this.apiService
      .removeAdmin(UserId)
      .subscribe((res) => this.getAccountUsers());
  }

  moveToCustomerPortal() {
    return this.apiService.getPortalLink().subscribe((res) => {
      return window.open(res, '_blank');
    });
  }

  onSubmit() {
    const checkEmail = this.users.find(
      (user) => user.email === this.emailForm.value.email
    );
    if (checkEmail) {
      return this.openPopupModel('Email already exists on this account');
    }
    if (!this.isCurrentUserAdmin) {
      return this.openPopupModel(
        'Access Denied: Only Admins Can Add Accounts.'
      );
    }

    this.apiService
      .addUserToAccount(this.emailForm.value.email)
      .pipe(
        catchError((error) => {
          if (error.message.includes(UserAccessibilityStatus.EXCEEDED_LIMIT)) {
            return EMPTY;
          }
          this.openPopupModel('Error: Invitation Not Sent');
          return EMPTY;
        })
      )
      .subscribe((res) => {
        if (res.addUsersToAccount === 'User added') {
          this.mixpanelService.trackEvent('new-user-invitation-sent');
          this.openPopupModel('Invitation Sent');
          this.getAccountUsers();
        } else {
          return this.openPopupModel(res.addUsersToAccount);
        }
      });
  }
  get email() {
    return this.emailForm.get('email');
  }

  openPopupModel(name: string) {
    this.dialogRef.open(PopupModelComponent, {
      data: {
        name,
        canClose: true,
      },
    });
  }

  setTitle() {
    this.titleService.setTitle(`Codenteam - Settings`);
  }
}
