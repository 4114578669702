import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { initSentry } from './app/core/sentry/sentry.init';
import { environment } from './environments/environment';

// Init Sentry if not local env
if (!environment.production) {
  console.log('environment just before init sentry');
  console.log(environment);
}

if (!environment.local) initSentry();

if (environment.production) {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  window.console.log = () => {};
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
