import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ITService } from './it-users.service';
import { ItUsersRoutingModule } from './it-users-routing.module';
import { RouterModule } from '@angular/router';
import { ItUsersAuthComponent } from './it-auth/it-auth.component';
import { ItUsersOperationsComponent } from './it-operations/it-operations.component';

@NgModule({
  declarations: [ItUsersAuthComponent, ItUsersOperationsComponent],
  imports: [
    CommonModule,
    FormsModule,
    MatIconModule,
    MatProgressSpinnerModule,
    ItUsersRoutingModule,
    RouterModule,
  ],
  providers: [ITService],
})
export class ItUsersModule {}
