import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ChatwootService {
  addWidget() {
    const h =
      document.head ||
      document.getElementsByTagName('head')[0] ||
      document.body;
    const BASE_URL = 'https://chat.codenteam.com';
    const file = '/packs/js/sdk.js';

    const s = document.createElement('script');
    s.type = 'text/javascript';
    s.async = true;
    s.src = BASE_URL + file;
    s.onload = () => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window as any).chatwootSDK.run({
        websiteToken: 'bdsQNXgUWx3wedcxCdMaoq6t', // cspell:disable-line
        baseUrl: BASE_URL,
      });

      // Inject custom CSS to hide elements
      const style = document.createElement('style');
      style.innerHTML = `
      @media print {
              .woot--bubble-holder,
              .woot-widget-holder {
                display: none !important;
              }
    }
            `;
      h.appendChild(style);
    };
    h.appendChild(s);
  }
}
