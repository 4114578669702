<div
  *ngrxLet="{
    licAndVuln: licensesAndVulnerabilitiesData$,
    account: account$,
    user: userInfo$,
    run: run$,
    logoPreview: logoPreview$
  } as context"
  class="report w-full"
>
  <div class="flex flex-col gap-4 bg-white px-5 pb-5 print:!mb-auto">
    <div class="flex flex-col gap-2 rounded-md bg-slate-200 p-2">
      <div class="flex justify-between">
        <p class="text-sm font-semibold text-slate-800">Licenses</p>
        <p>
          Types of Licenses Used:
          {{ context.licAndVuln.licenses.length ?? 0 }}
        </p>
      </div>
      <div class="grid grid-cols-12 gap-2">
        <!-- 1st table -->
        <div
          *ngIf="context.licAndVuln.licenses?.length > 0"
          class="col-span-4 text-[8px]"
        >
          <div class="flex justify-between px-1.5 font-normal">
            <p>License</p>
            <p class="font-medium">Count</p>
          </div>
          <div class="rounded-[5px] border-[1px] border-slate-400 px-1.5 py-1">
            <table class="w-full table-auto border-collapse text-[10px]">
              <tbody>
                <tr
                  *ngFor="let lic of context.licAndVuln.licenses; let i = index"
                  [hidden]="i % 3 !== 0"
                >
                  <td class="px-1.5 py-1 font-normal">
                    {{ lic.licenseName }}
                  </td>
                  <td class="px-1.5 py-1 text-right font-semibold">
                    {{ lic.count }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- 2nd table -->
        <div
          *ngIf="context.licAndVuln.licenses.length > 1"
          class="col-span-4 text-[8px]"
        >
          <div class="flex justify-between px-1.5 font-normal">
            <p>License</p>
            <p class="font-medium">Count</p>
          </div>
          <div class="rounded-[5px] border-[1px] border-slate-400 px-1.5 py-1">
            <table class="w-full table-auto border-collapse text-[10px]">
              <tbody>
                <tr
                  *ngFor="
                    let lic of context.licAndVuln?.licenses;
                    let i = index
                  "
                  [hidden]="i % 3 !== 1"
                >
                  <td class="px-1.5 py-1 font-normal">
                    {{ lic.licenseName }}
                  </td>
                  <td class="px-1.5 py-1 text-right font-semibold">
                    {{ lic.count }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- 3rd table -->
        <div
          *ngIf="context.licAndVuln.licenses?.length > 2"
          class="col-span-4 text-[8px]"
        >
          <div class="flex justify-between px-1.5 font-normal">
            <p>License</p>
            <p class="font-medium">Count</p>
          </div>
          <div class="rounded-[5px] border-[1px] border-slate-400 px-1.5 py-1">
            <table class="w-full table-auto border-collapse text-[10px]">
              <tbody>
                <tr
                  *ngFor="let lic of context.licAndVuln.licenses; let i = index"
                  [hidden]="i % 3 !== 2"
                >
                  <td class="px-1.5 py-1 font-normal">
                    {{ lic.licenseName }}
                  </td>
                  <td class="px-1.5 py-1 text-right font-semibold">
                    {{ lic.count }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <!-- If no licenses -->
        <div
          *ngIf="context.licAndVuln.licenses?.length === 0"
          class="col-span-12 text-center text-xl text-slate-400"
        >
          No Licenses Available
        </div>
      </div>
    </div>

    <div class="mt-5 flex flex-col gap-2 rounded-md bg-slate-200 p-2">
      <p class="text-sm font-semibold text-slate-800">
        Dependency Vulnerabilities
      </p>
      <div class="grid grid-cols-12 gap-2">
        <!-- 1st table -->
        <div
          class="col-span-4"
          *ngIf="orderedVuln.table1.length > 0"
        >
          <div class="flex justify-between px-1.5 text-[8px]">
            <p class="font-normal">Name</p>
            <div class="flex gap-2.5 pr-1.5 font-medium">
              <p>Highest Risk</p>
              <p>Risks</p>
            </div>
          </div>
          <div class="rounded-[5px] border-[1px] border-slate-400 px-1.5 py-1">
            <table class="w-full table-auto border-collapse text-[10px]">
              <tbody>
                <tr *ngFor="let vuln of orderedVuln.table1">
                  <td class="px-1.5 py-1 font-normal text-slate-900">
                    {{ vuln.package }}
                  </td>
                  <td
                    class="px-1.5 py-1 text-center font-semibold"
                    [ngClass]="getScoreColor(vuln.score)"
                  >
                    {{ vuln.score }}
                  </td>
                  <td
                    class="px-1.5 py-1 text-center font-semibold text-slate-900"
                  >
                    {{ vuln.count }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- 2nd table -->
        <div
          class="col-span-4"
          *ngIf="orderedVuln.table2.length > 0"
        >
          <div class="flex justify-between px-1.5 text-[8px]">
            <p class="font-normal">Name</p>
            <div class="flex gap-2.5 pr-1.5 font-medium">
              <p>Highest Risk</p>
              <p>Risks</p>
            </div>
          </div>
          <div class="rounded-[5px] border-[1px] border-slate-400 px-1.5 py-1">
            <table class="w-full table-auto border-collapse text-[10px]">
              <tbody>
                <tr *ngFor="let vuln of orderedVuln.table2">
                  <td class="px-1.5 py-1 font-normal text-slate-900">
                    {{ vuln.package }}
                  </td>
                  <td
                    class="px-1.5 py-1 text-center font-semibold"
                    [ngClass]="getScoreColor(vuln.score)"
                  >
                    {{ vuln.score }}
                  </td>
                  <td
                    class="px-1.5 py-1 text-center font-semibold text-slate-900"
                  >
                    {{ vuln.count }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- 3rd table -->
        <div
          class="col-span-4"
          *ngIf="orderedVuln.table3.length > 0"
        >
          <div class="flex justify-between px-1.5 text-[8px]">
            <p class="font-normal">Name</p>
            <div class="flex gap-2.5 pr-1.5 font-medium">
              <p>Highest Risk</p>
              <p>Risks</p>
            </div>
          </div>
          <div class="rounded-[5px] border-[1px] border-slate-400 py-1 pl-1.5">
            <table class="w-full table-auto border-collapse text-[10px]">
              <tbody>
                <tr *ngFor="let vuln of orderedVuln.table3">
                  <td class="px-1.5 py-1 font-normal text-slate-900">
                    {{ vuln.package }}
                  </td>
                  <td
                    class="px-1.5 py-1 text-center font-semibold"
                    [ngClass]="getScoreColor(vuln.score)"
                  >
                    {{ vuln.score }}
                  </td>
                  <td
                    class="px-1.5 py-1 text-center font-semibold text-slate-900"
                  >
                    {{ vuln.count }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <!-- If no Vulnerabilities -->
        <div
          *ngIf="orderedVuln.table1.length === 0"
          class="col-span-12 text-center text-xl text-slate-400"
        >
          No Vulnerabilities Available
        </div>
      </div>
    </div>
  </div>
</div>
