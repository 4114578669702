<mat-toolbar class="flex dark:bg-slate-700">
  <button
    mat-icon-button
    *ngrxLet="this.sidenavService.sidenavExpanded$ | async"
    (click)="toggleSide = !toggleSide"
    class="flex"
  >
    <mat-icon class="dark:text-blue-400">menu</mat-icon>
  </button>
  <!-- desktop -->
  <div class="hidden flex-1 lg:flex">
    <a
      class="ml-2 flex"
      (click)="gotoHomePage()"
    >
      <img
        class="brand w-32 cursor-pointer object-contain text-xl"
        data-test="logo-top"
        src="../../../assets/logo.png"
      />
    </a>

    <codenteam-search-form class="mx-10 flex flex-1"></codenteam-search-form>
    <div class="ml-auto flex items-center justify-between pr-10">
      <div class="mb-0 ml-4 flex items-center">
        <div class="hidden lg:block">
          <p class="text-xs text-gray-500 dark:text-gray-500">Account</p>
          <p class="text-sm dark:text-white">{{ name }}</p>
          <p class="text-xs text-gray-500 dark:text-gray-400">
            {{ email }}
          </p>
        </div>
        <div class="ml-auto content-center">
          <div
            class="relative ml-20 h-10 w-10 overflow-hidden rounded-full bg-gray-300 text-gray-600"
          >
            <mat-icon
              class="!h-10 !w-10"
              svgIcon="single-developer"
            ></mat-icon>
          </div>
        </div>
        <mat-icon
          [matMenuTriggerFor]="menu"
          class="ml-3 mt-3 cursor-pointer text-gray-600 dark:text-white"
          >keyboard_arrow_down</mat-icon
        >
        <mat-menu
          #menu="matMenu"
          class="!bg-slate-800"
        >
          <button
            mat-menu-item
            (click)="logOut()"
            data-test="logout-button"
          >
            Log Out
          </button>
        </mat-menu>
        <codenteam-notifications
          *ngIf="'notifications' | featureflag$ | async"
        ></codenteam-notifications>
      </div>
    </div>
  </div>

  <!-- Mobile -->
  <div class="flex flex-1 lg:hidden">
    <a
      class="flex flex-1"
      (click)="gotoHomePage()"
      *ngIf="!searchExpand"
    >
      <img
        class="brand w-32 cursor-pointer object-contain text-xl"
        src="../../../assets/logo.png"
      />
    </a>
    <codenteam-search-form
      *ngIf="searchExpand"
      class="flex w-28 flex-1"
    ></codenteam-search-form>

    <button
      mat-icon-button
      color="primary"
      (click)="searchExpand = !searchExpand"
    >
      <mat-icon>{{ !searchExpand ? 'search' : 'close' }}</mat-icon>
    </button>

    <div class="flex items-center justify-between">
      <div class="mb-0 ml-4 flex">
        <div class="hidden lg:block">
          <p class="text-xs text-gray-500 dark:text-gray-500">Account</p>
          <p class="text-sm dark:text-white">{{ name }}</p>
          <p class="text-xs text-gray-500 dark:text-gray-400">
            {{ email }}
          </p>
        </div>
        <div>
          <div
            class="relative h-10 w-10 overflow-hidden rounded-full bg-gray-300 text-gray-600"
          >
            <svg
              class="absolute h-12 w-12 pr-2 text-gray-500"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </div>
        </div>
        <mat-icon
          [matMenuTriggerFor]="menu"
          class="ml-3 mt-2 cursor-pointer text-gray-600 dark:text-white"
          data-test="down-arrow"
          >keyboard_arrow_down</mat-icon
        >
        <mat-menu
          #menu="matMenu"
          class="!bg-slate-800"
        >
          <button
            mat-menu-item
            (click)="logOut()"
            data-test="logout-button"
          >
            Log Out
          </button>
        </mat-menu>
        <codenteam-notifications
          *ngIf="'notifications' | featureflag$ | async"
        ></codenteam-notifications>
      </div>
    </div>
  </div>
</mat-toolbar>
<mat-sidenav-container
  class="block h-[calc(100%-64px)] w-full dark:bg-slate-900"
  autosize
  [hasBackdrop]="false"
>
  <mat-sidenav
    #navbar
    mode="side"
    opened="true"
    class="flex !min-h-full w-auto border-0 dark:bg-slate-900"
    [ngClass]="toggleSide ? '' : 'collapsed max-w-0 md:max-w-[67px]'"
  >
    <div class="main-sidenav-div flex !h-full flex-col">
      <mat-accordion
        class="app-nav-accordion !h-full"
        [multi]="true"
      >
        <mat-nav-list
          class="!flex !h-full flex-1 !flex-col pb-0"
          [ngClass]="toggleSide ? 'w-60' : 'w-[61px]'"
        >
          <mat-list-item
            *ngIf="runId"
            (click)="goToRunParent()"
            [ngClass]="!toggleSide ? 'min-w-14 ' : ''"
            class="!min-h-7 items-center"
          >
            <div class="flex gap-2 rounded-md bg-slate-700 py-1">
              <mat-icon [ngClass]="toggleSide ? 'ml-2' : ''"
                >arrow_back</mat-icon
              >
              <span
                *ngIf="toggleSide"
                class="font-mada font-medium"
                >Back to Project</span
              >
            </div>
          </mat-list-item>

          <mat-list-item
            [ngClass]="
              runId ? (!toggleSide ? 'max-w-10' : 'max-w-52') : '!hidden'
            "
            (click)="onNavigate()"
            [routerLink]="runHomeRoute"
            routerLinkActive="!bg-[#1A9AF1] !rounded-md"
            [routerLinkActiveOptions]="{ exact: true }"
            class="!my-2 mx-auto !max-h-8 !min-h-8 !py-0.5"
          >
            <mat-icon
              matListItemIcon
              matTooltip="Overview"
              svgIcon="overview"
              class="!h-5 !w-5 !text-white"
              [ngClass]="!toggleSide ? '!ml-[0.65rem]' : '!ml-5 !mr-[10px]'"
            ></mat-icon>
            <span *ngIf="toggleSide">Overview</span>
          </mat-list-item>

          <div class="inner-sidenav-div flex flex-col">
            <!---------------------------------------->
            <!--for Ownership part-->
            <ng-container *ngrxLet="ownershipMenu$ as ownershipMenu">
              <codenteam-generic-expansion-panel
                *ngIf="runId"
                [headerName]="'Ownership'"
                [list]="ownershipMenu"
                [toggleSide]="toggleSide"
                [canEdit]="canEdit$ | async"
                [expanded]="true"
                [show]="true"
                (toggleEmitter)="setToggleSide($event)"
              >
              </codenteam-generic-expansion-panel>
            </ng-container>

            <!--for assessments part-->
            <ng-container *ngrxLet="assessmentsMenu$ as assessmentsMenu">
              <codenteam-generic-expansion-panel
                *ngIf="runId"
                [headerName]="'Assessments'"
                [list]="assessmentsMenu"
                [toggleSide]="toggleSide"
                [canEdit]="canEdit$ | async"
                [expanded]="true"
                [show]="true"
                (toggleEmitter)="setToggleSide($event)"
              >
              </codenteam-generic-expansion-panel>
            </ng-container>

            <!--for edit part-->
            <ng-container *ngrxLet="editsMenu$ as editsMenu">
              <codenteam-generic-expansion-panel
                *ngIf="runId"
                [headerName]="'Edit'"
                [list]="editsMenu"
                [toggleSide]="toggleSide"
                [canEdit]="canEdit$ | async"
                [isProfileNotAssigned]="isProfileNotAssigned"
                [isAuthorNotAssigned]="isAuthorNotAssigned"
                [expanded]="true"
                [show]="canEdit$ | async"
                (toggleEmitter)="setToggleSide($event)"
              >
              </codenteam-generic-expansion-panel>
            </ng-container>

            <!------------------------------------------>

            <mat-list-item
              *ngIf="('project-hub' | featureflag$ | async) && !runId"
              (click)="goToProjectsHubPage()"
            >
              <mat-icon
                matListItemIcon
                matTooltip="Projects"
                class="!text-blue-400"
                svgIcon="project-hub"
              ></mat-icon>
              Projects
            </mat-list-item>

            <mat-list-item
              (click)="gotoRunsList()"
              *ngIf="!runId"
            >
              <mat-icon
                matListItemIcon
                matTooltip="My Runs"
                class="!text-blue-400"
                svgIcon="my-runs"
              ></mat-icon>
              My Runs
            </mat-list-item>
            <mat-list-item
              (click)="gotoSharedRunsList()"
              *ngIf="('share-runs' | featureflag$ | async) === true && !runId"
            >
              <mat-icon
                matListItemIcon
                matTooltip="Shared With Me"
                class="!text-blue-400"
                svgIcon="shared-with-me"
              ></mat-icon>
              Shared With Me
            </mat-list-item>
            <mat-list-item
              *ngIf="('hiring-jobs' | featureflag$ | async) && !runId"
              (click)="goToHiringJobListingPage()"
              trackClick="hiring-bttn-sidenav"
            >
              <mat-icon
                matListItemIcon
                matTooltip="Hiring"
                svgIcon="hiring-new-icon"
                class="dark:!text-blue-400"
              ></mat-icon>
              Hiring
            </mat-list-item>
            <mat-list-item
              *ngIf="('scans' | featureflag$ | async) && !runId"
              (click)="gotoScans()"
            >
              <mat-icon
                matListItemIcon
                matTooltip="Scans"
                class="dark:!text-blue-400"
                >security</mat-icon
              >
              Scans
            </mat-list-item>
            <mat-list-item
              *ngIf="('investor-hub' | featureflag$ | async) && !runId"
              (click)="goToInvestorHubPage()"
            >
              <mat-icon
                matListItemIcon
                matTooltip="Investors Hub"
                class="!text-blue-400"
                >rocket</mat-icon
              >
              Investors Hub
            </mat-list-item>
            <mat-list-item
              matTooltip="Reports Center"
              (click)="gotoReport()"
              *ngIf="('report' | featureflag$ | async) === true && !runId"
            >
              <mat-icon
                matListItemIcon
                class="dark:!text-blue-400"
                >assignment</mat-icon
              >
              <a
                matListItemTitle
                *ngIf="toggleSide"
                >Reports Center</a
              >
            </mat-list-item>
          </div>
          <mat-nav-list class="!min-h-30 !mt-auto !flex !flex-col">
            <div
              class="!flex"
              [ngClass]="
                toggleSide
                  ? '!mx-2 !justify-between'
                  : '!flex-col !items-center !justify-center gap-2'
              "
            >
              <mat-list-item
                matTooltip="Start tour"
                data-test="start-tour"
                (click)="startTour()"
                [ngClass]="toggleSide ? '!flex !gap-1.5 ' : ''"
                class="!h-8 !max-w-min !px-2.5 !py-1"
              >
                <mat-icon
                  matListItemIcon
                  class="!m-0 !h-8 !w-6 !p-0 !text-2xl !text-white"
                  >help</mat-icon
                >
                <a
                  matListItemTitle
                  class="!text-sm"
                  *ngIf="toggleSide"
                  >Help</a
                >
              </mat-list-item>
              <mat-list-item
                matTooltip="Settings"
                [ngClass]="toggleSide ? '!flex !gap-1.5 ' : ''"
                class="!h-8 !max-w-min !px-2.5 !py-1"
                (click)="gotoAccountUsersPage()"
              >
                <mat-icon
                  matListItemIcon
                  class="!m-0 !h-8 !w-6 !text-2xl !text-white"
                  >settings</mat-icon
                >
                <a
                  matListItemTitle
                  class="!text-sm"
                  *ngIf="toggleSide"
                  >Settings</a
                >
              </mat-list-item>
            </div>
            <div
              class="m-2 flex min-w-11 items-center gap-2 rounded-md bg-slate-700 p-2"
            >
              <mat-icon
                svgIcon="profile-icon"
                class="!h-8 !w-7 min-w-7 !text-2xl"
                [ngClass]="toggleSide ? '' : 'mx-auto flex'"
                [matTooltip]="name"
              ></mat-icon>
              <div
                *ngIf="toggleSide"
                class="flex flex-col"
              >
                <span
                  class="font-mada max-w-40 truncate text-sm font-medium"
                  [matTooltip]="name"
                  >{{ name }}</span
                >
                <span
                  class="font-mada max-w-40 truncate text-xs font-medium text-gray-400"
                  [matTooltip]="email"
                  >{{ email }}</span
                >
              </div>
            </div>
          </mat-nav-list>
        </mat-nav-list>
      </mat-accordion>
    </div>
  </mat-sidenav>
  <mat-sidenav-content
    class="back py-3 pl-0 pr-0 md:pl-3 md:pr-2"
    [class.opened]="toggleSide"
  >
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
