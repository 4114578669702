<div
  *ngrxLet="{
    scan: scan$,
    account: account$,
    user: userInfo$,
    logoPreview: logoPreview$
  } as context"
  class="report"
>
  <div class="w-full flex-col bg-white text-white">
    <div class="top-center">
      <codenteam-report-header
        [account]="context.account"
        [user]="context.user"
        [logo]="context?.logoPreview"
      ></codenteam-report-header>
      <div
        class="mx-5 mb-4 flex items-center justify-between rounded-md bg-slate-200 p-3"
      >
        <div class="flex flex-row gap-1">
          <mat-icon class="!-mt-2 !h-10 !text-2xl !text-slate-900"
            >security</mat-icon
          >
          <div class="flex flex-col gap-1">
            <div class="text-xl font-medium leading-4 text-slate-900">
              Scanning Result {{ index + 1 }}
            </div>
            <div class="flex flex-row gap-1 text-slate-900">
              <p class="text-sm text-zinc-600">Type of scan:</p>
              <p class="text-sm text-slate-900">{{ context.scan.type }} Scan</p>
            </div>
          </div>
        </div>
        <div class="flex flex-col gap-1">
          <div class="flex justify-center leading-3">
            <p
              class="text-slate-900"
              *ngIf="context.scan.scanName"
            >
              ({{ context.scan.scanName }})
            </p>
          </div>
          <a
            [href]="context.scan.website"
            style="color: #6db4fc"
            >{{ context.scan.website }}</a
          >
        </div>
        <div class="flex flex-col text-sm text-gray-600">
          <p>{{ context.scan.createdAt | date : 'dd/MM/yyyy' }}</p>
          <p>{{ context.scan.createdAt | date : 'h:mm a' }}</p>
        </div>
      </div>
    </div>
  </div>

  <div class="flex flex-col gap-4 bg-white px-5">
    <div class="flex gap-5 print:!mb-auto">
      <div
        class="flex h-48 w-1/2 flex-col items-center justify-center gap-4 rounded-md bg-slate-200 p-6"
      >
        <codenteam-scan-gauge
          [percentage]="calculateRiskPercentage(context.scan.report.alerts)"
          [data]="gaugeData"
          [radius]="100"
          class="place-content-center"
        ></codenteam-scan-gauge>
        <div class="text-2xl text-slate-900">Risk</div>
      </div>
      <div
        class="flex h-48 w-1/2 flex-col items-center justify-center rounded-md bg-slate-200 p-6"
      >
        <div class="font-mada mb-3 font-medium">Total Number of Risks</div>
        <div class="mb-2 text-5xl font-bold leading-10">
          {{ countRisks(context.scan?.report.alerts, 4) }}
        </div>
        <div class="flex gap-4 rounded-md bg-slate-300 px-6 py-1.5">
          <div class="flex flex-col">
            <mat-icon class="!h-6 !w-[18px] !text-xl !text-blue-600"
              >info</mat-icon
            >
            <div class="pl-1.5 text-sm font-medium">
              {{ countRisks(context.scan?.report.alerts, 0) }}
            </div>
          </div>
          <div class="flex flex-col">
            <mat-icon class="!h-6 !w-[18px] !text-xl !text-yellow-600"
              >cancel</mat-icon
            >
            <div class="pl-1.5 text-sm font-medium">
              {{ countRisks(context.scan?.report.alerts, 1) }}
            </div>
          </div>
          <div class="flex flex-col">
            <mat-icon class="!h-6 !w-[18px] !text-xl !text-orange-600"
              >cancel</mat-icon
            >
            <div class="pl-1.5 text-sm font-medium">
              {{ countRisks(context.scan?.report.alerts, 2) }}
            </div>
          </div>
          <div class="flex flex-col">
            <mat-icon class="!h-6 !w-[18px] !text-xl !text-red-600"
              >cancel</mat-icon
            >

            <div class="pl-1.5 text-sm font-medium">
              {{ countRisks(context.scan?.report.alerts, 3) }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex justify-between px-4">
      <div class="text-sm">Points</div>
      <div class="flex gap-12">
        <div class="text-sm">Confidence</div>
        <div class="text-sm">Risk</div>
      </div>
    </div>
    <div>
      <div
        *ngFor="let alert of context.scan?.report.alerts; let i = index"
        [id]="i.toString()"
        class="single-scan-result flex-col p-4 odd:!bg-slate-300 even:!bg-slate-200 print:mb-auto print:break-inside-avoid"
      >
        <div class="flex justify-between !text-slate-900">
          <div class="text-lg text-slate-800">{{ alert.name }}</div>
          <div class="flex w-36 justify-between">
            <div class="flex items-center">
              <codenteam-confidence-level
                [levels]="confidenceLevels"
                [confidenceLevel]="alert.confidence"
              ></codenteam-confidence-level
              ><span class="text-sm"
                >{{ alert.confidence + 1 }}/{{ confidenceLevels }}</span
              >
            </div>
            <mat-icon
              *ngIf="alert.risk > 0"
              [ngClass]="
                alert.risk === 1
                  ? '!text-yellow-600'
                  : alert.risk === 2
                  ? '!text-orange-600'
                  : alert.risk === 3
                  ? '!text-red-600'
                  : ''
              "
              class="!mt-0.5"
              >cancel</mat-icon
            >
            <mat-icon
              *ngIf="alert.risk === 0"
              class="!mt-0.5 !text-blue-600"
              >info</mat-icon
            >
          </div>
        </div>
        <div
          *ngIf="alert.desc.length < 2500"
          [innerHTML]="alert.desc"
          class="pt-1 text-sm leading-[18px] text-slate-900"
        ></div>
        <ul class="w-full list-inside list-disc">
          <li
            *ngFor="let instance of alert.instances.slice(0, 3)"
            class="break-words text-sm leading-[18px] text-slate-900"
          >
            {{ instance.method }} {{ instance.uri }}
          </li>
          <li
            *ngIf="alert.instances.length > 3"
            class="text-sm leading-[18px] text-slate-900"
          >
            + {{ alert.instances.length - 3 }} more
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
