import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { ScanGaugeComponent } from '@codenteam/ui/scan-gauge/scan-gauge.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { ReportHeaderComponent } from '@codenteam/ui/report/report-header/report-header.component';

import { ApiService } from '../../core/api.service';

import { Alert, Report, PentestScan } from '@codenteam/portal/graphql';
import { LetDirective } from '@ngrx/component';
import { Observable, tap } from 'rxjs';
import { MatTooltipDirective } from './mat-tootltip-dummy.directive';
import { ConfidenceLevelComponent } from '@codenteam/ui/confidence-level/confidence-level.component';

@Component({
  selector: 'codenteam-pentest-scan-report',
  standalone: true,
  imports: [
    CommonModule,
    MatIcon,
    ScanGaugeComponent,
    MatExpansionModule,
    MatTooltipDirective,
    LetDirective,
    ReportHeaderComponent,
    ConfidenceLevelComponent,
  ],
  templateUrl: './pentest-scan-report.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class PentestScanReportComponent implements OnInit {
  @Input()
  uuid: string;
  @Input()
  index: number;

  @Input()
  logoPreview$: Observable<string | ArrayBuffer>;

  @Output()
  completed = new EventEmitter<string>();
  scan$: Observable<PentestScan>;
  elementsBreak: number[];
  confidenceLevels = 5;
  account$ = this.apiService.getAccountData();
  userInfo$ = this.apiService.getUserInfo();
  currentDate = new Date();
  gaugeData = [
    { label: '1', value: 1, color: '#6EF140' },
    { label: '2', value: 1, color: '#F8CD47' },
    { label: '3', value: 1, color: '#E8642D' },
    { label: '4', value: 1, color: '#CF3E3E' },
  ];
  confidenceWeight = [10, 35, 65, 90];
  ngOnInit(): void {
    this.scan$ = this.apiService.singleScan(this.uuid).pipe(
      tap(() => {
        this.completed.emit(this.uuid);
      })
    );
  }

  constructor(private apiService: ApiService) {}

  calculateRiskPercentage(alerts: Alert[]): number {
    const highestConfidence = Math.max(...alerts.map((alert) => alert.risk));
    const percentage = this.confidenceWeight[highestConfidence];
    return percentage;
  }
  countRisks(alerts: Array<Alert>, risk: number) {
    let totalRisk = 0;
    if (risk === 4) {
      for (let index = 0; index < risk; index++) {
        totalRisk += alerts.filter((item) => item.risk === index).length;
      }
      return totalRisk;
    }
    const number = alerts.filter((item) => item.risk === risk).length;
    return number;
  }
}
