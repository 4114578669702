import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { ScanGaugeComponent } from '@codenteam/ui/scan-gauge/scan-gauge.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { ReportHeaderComponent } from '@codenteam/ui/report/report-header/report-header.component';

import { ScanningCodeError } from '@codenteam/portal/graphql';
import { LetDirective } from '@ngrx/component';
import { map, Observable } from 'rxjs';
import { ApiService } from '../../core/api.service';
import {
  ScanningCodeVulnTypes,
  scanningCodeSeverityOrder,
} from '@codenteam/core/utils/dtos/scanning-code-vuln-types.enum';
import { ReportPausedAnalysisMessageComponent } from '@codenteam/ui/report-paused-analysis-message/report-paused-analysis-message.component';

@Component({
  selector: 'codenteam-scanning-code-report',
  standalone: true,
  imports: [
    CommonModule,
    MatIcon,
    ScanGaugeComponent,
    MatExpansionModule,
    LetDirective,
    ReportHeaderComponent,
    ReportPausedAnalysisMessageComponent,
  ],
  templateUrl: './scanning-code-report.component.html',
})
export class ScanningCodeReportComponent implements OnInit {
  @Input()
  uuid: string;

  scan$: Observable<ScanningCodeError[]>;
  run$: Observable<{ uuid: string; name?: string; date?: any; status: string }>;
  elementsBreak: number[];
  account$ = this.apiService.getAccountData();
  userInfo$ = this.apiService.getUserInfo();
  currentDate = new Date();
  examplesCount = 2;
  gaugeData = [
    { label: '1', value: 1, color: '#6EF140' },
    { label: '2', value: 1, color: '#F8CD47' },
    { label: '3', value: 1, color: '#E8642D' },
    { label: '4', value: 1, color: '#CF3E3E' },
  ];
  severityLevels: { [key: string]: number } = {
    info: 0,
    error: 1,
    critical: 2,
  };

  severityWeight = [10, 65, 90];
  @Input()
  logoPreview$: Observable<string | ArrayBuffer>;

  ngOnInit(): void {
    this.run$ = this.apiService.getRun(this.uuid);

    this.scan$ = this.apiService.getCodeScanResultForRun(this.uuid).pipe(
      map((errors: ScanningCodeError[]) => {
        return errors.sort((a, b) => {
          return (
            scanningCodeSeverityOrder[a.severity as ScanningCodeVulnTypes] -
            scanningCodeSeverityOrder[b.severity as ScanningCodeVulnTypes]
          );
        });
      })
    );
  }

  constructor(private apiService: ApiService) {}

  calculateRiskPercentage(errors: ScanningCodeError[]): number {
    const highestSeverity = errors.reduce((maxSeverity, error) => {
      return this.severityLevels[error.severity] > maxSeverity
        ? this.severityLevels[error.severity]
        : maxSeverity;
    }, 0);
    const percentage = this.severityWeight[highestSeverity];
    return percentage;
  }

  countErrors(errors: ScanningCodeError[], error: number): number {
    let severity: string;

    /**
     * If combined is true, all risks of same type are counted together.
     */
    const combined = true;

    switch (error) {
      case 0:
        severity = 'info';
        break;
      case 2:
        severity = 'error';
        break;
      case 3:
        severity = 'critical';
        break;
      case 4: {
        if (combined) {
          return errors.reduce((count, err) => count + 1, 0);
        } else {
          return errors.reduce((count, err) => count + err.instances.length, 0);
        }
      }
    }

    if (combined) {
      return errors
        .filter((err) => err.severity === severity)
        .reduce((count, err) => count + 1, 0);
    } else {
      return errors
        .filter((err) => err.severity === severity)
        .reduce((count, err) => count + err.instances.length, 0);
    }
  }
}
