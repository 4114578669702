import { Component, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { RoutesService } from '@codenteam/core/routes';
import { Title } from '@angular/platform-browser';
import { ApiService } from '../core/api.service';
import { ActivityType } from '@codenteam/core/utils/dtos/activity-types.enum';
import { CommonModule } from '@angular/common';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { LetDirective } from '@ngrx/component';
import { NotificationsService } from '../core/notifications.service';
import { NotificationType, StateOptions } from '@codenteam/portal/graphql';
import { NotificationCoreService } from '../layouts/notifications/notification-core.service';
import { FeatureFlagModule } from '@codenteam/core/feature-flags/frontend';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'codenteam-lib-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    MatListModule,
    MatIconModule,
    LetDirective,
    RouterModule,
    FeatureFlagModule,
  ],
})
export class HomeComponent implements OnInit {
  constructor(
    private router: Router,
    private routesService: RoutesService,
    private http: HttpClient,
    public titleService: Title,
    public apiService: ApiService,
    private notificationService: NotificationsService,
    public notificationCoreService: NotificationCoreService
  ) {}

  activity$ = this.notificationService.getAllUserNotifications();
  isSlackReady$ = this.http.get<boolean>(`/api/slack/is-slack-ready`);
  activityTypes = ActivityType;

  icon(type: string): { color: string; icon: string } {
    if (type === 'DastCompletedNotification') {
      return {
        color: '!text-cyan-400 !font-[26px] !w-8 !h-8',
        icon: 'shield-icon',
      };
    }
    if (type === 'RunCompletedNotification') {
      return {
        color: '!text-violet-400 !font-[26px] !w-8 !h-8',
        icon: 'tag',
      };
    }
    if (type === 'RunSharedNotification') {
      return { color: '!text-slate-400 !font-[26px] !w-8 !h-8', icon: 'share' };
    }
    return { color: '', icon: '' };
  }
  ngOnInit() {
    this.titleService.setTitle(`CodenTeam - Home`);
  }

  notificationNavigate(notification: NotificationType) {
    if (notification.data.extra.state !== StateOptions.Failed) {
      this.notificationCoreService.navigate(notification);
    }
  }

  goToProjectsHubPage() {
    this.router.navigate(this.routesService.projectHub());
  }

  goToHiringJobListingPage() {
    this.router.navigate(this.routesService.jobListing());
  }

  goToInvestorHubPage() {
    this.router.navigate(this.routesService.investorHub());
  }

  gotoAccountUsersPage() {
    this.router.navigate(this.routesService.settings());
  }

  gotoReport() {
    this.router.navigate(this.routesService.report2());
  }

  getSlackInstallationLink() {
    return '/api/slack-receiver/installations';
  }
}
