import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { PagedService } from '../../core/paged/paged.service';
import { CompanyAnalysisReportComponent } from '../company-analysis-report/company-analysis-report.component';
import { ScanningCodeReportComponent } from '../scanning-code-report/scanning-code-report.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TeamsAnalysisReportComponent } from '../teams-analysis-report/teams-analysis-report.component';
import { ApiService } from '../../core/api.service';
import { map, Observable, tap } from 'rxjs';
import { Team } from '@codenteam/portal/graphql';
import { Title } from '@angular/platform-browser';
import { PentestScanReportComponent } from '../pentest-scan-report/pentest-scan-report.component';
@Component({
  selector: 'codenteam-report-review',
  standalone: true,
  imports: [
    CommonModule,
    PentestScanReportComponent,
    CompanyAnalysisReportComponent,
    ScanningCodeReportComponent,
    MatProgressSpinnerModule,
    TeamsAnalysisReportComponent,
  ],
  templateUrl: './report-review.component.html',
  styleUrl: './report-review.component.scss',
  providers: [PagedService],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportReviewComponent {
  arrayOfScansUUID: Array<string>;
  arrayOfRunsUUID: Array<string>;
  loadingDone: Record<string, boolean> = {};

  loadingAllDone = false;
  runTeams$: Record<string, Observable<Team[]>> = {};
  print = false;
  logoPreview$: Observable<string | ArrayBuffer> =
    this.apiService.downloadLogoUrl();

  constructor(
    private activatedRoute: ActivatedRoute,
    private pagedService: PagedService,
    private changeDetectorRef: ChangeDetectorRef,
    private apiService: ApiService,
    private titleService: Title
  ) {
    this.titleService.setTitle(`Codenteam - Report`);
    const scansArray =
      this.activatedRoute.snapshot.queryParamMap.get('scansArray');
    const runsArray =
      this.activatedRoute.snapshot.queryParamMap.get('runsArray');
    this.print =
      this.activatedRoute.snapshot.queryParamMap.get('print') === 'true';

    /**
     * We have one huge assumption here:
     * If you have run uuids and Pentest, then the run will be slower to load, so Pentest can always be assumed to be loaded.
     *
     */
    if (runsArray === null) {
      this.arrayOfRunsUUID = new Array<string>();
    } else {
      this.arrayOfRunsUUID = JSON.parse(runsArray);

      // Need to wait for the runs to be done
      for (const uuid of this.arrayOfRunsUUID) {
        this.loadingDone[`run-${uuid}`] = false;
        this.runTeams$[uuid] = this.apiService
          .getTeamsWithProfilesForReport(uuid)
          .pipe(
            tap((res) => {
              for (const team of res) {
                this.loadingDone[`team-${team.id}`] = false;
              }
            }),
            map((res) => res)
          );
        this.loadingDone[`scanning code-${uuid}`] = true;
      }
    }

    if (scansArray === null) {
      this.arrayOfScansUUID = new Array<string>();
    } else {
      this.arrayOfScansUUID = JSON.parse(scansArray);
      for (const uuid of this.arrayOfScansUUID) {
        this.loadingDone[`pentest-${uuid}`] = true;
        this.checkIfAllLoaded();
      }
    }
  }

  onCompleteGeneric(prefix: string, uuid: string, completed: boolean) {
    this.loadingDone[`${prefix}-${uuid}`] = completed;
    this.checkIfAllLoaded();
  }

  onComplete(uuid: string, completed: boolean) {
    this.loadingDone[`run-${uuid}`] = completed;
    this.checkIfAllLoaded();
    // Check if all uuids are done
    for (const uuid of this.arrayOfRunsUUID) {
      if (!this.loadingDone[`run-${uuid}`]) {
        return;
      }
    }
  }
  checkIfAllLoaded() {
    let allDone = true;
    for (const key in this.loadingDone) {
      if (!this.loadingDone[key]) {
        allDone = false;
        break;
      }
    }
    if (allDone && !this.loadingAllDone) {
      this.loadingAllDone = true;
      this.setForPrint();
      this.changeDetectorRef.markForCheck();
    }
  }
  onTeamComplete(teamId: number, teams: Team[], completed: boolean) {
    this.loadingDone[`team-${teamId}`] = true;
    this.checkIfAllLoaded();
  }
  onPentestComplete(teamId: number, teams: Team[], completed: boolean) {
    this.loadingDone[`team-${teamId}`] = true;
    this.checkIfAllLoaded();
  }
  loadingText() {
    const assetDone: Record<string, number[]> = {};
    const keys = ['team', 'run'];

    for (const key in this.loadingDone) {
      for (const k of keys) {
        if (key.startsWith(k + '-')) {
          assetDone[k] = assetDone[k] || [0, 0];
          assetDone[k][0] += 1;
          if (this.loadingDone[key]) {
            assetDone[k][1] += 1;
          }
        }
      }
    }
    // Add Pentest and Scanning Code as scans
    for (const key in assetDone) {
      if (key === 'pentest' || key === 'scanning code') {
        assetDone['scan'] = assetDone['scan'] || [0, 0];
        assetDone['scan'][0] += assetDone[key][0];
        assetDone['scan'][1] += assetDone[key][1];
      }
    }
    let loadingStr = 'Loading ';
    for (const key in assetDone) {
      loadingStr += ` ${assetDone[key][1]}/${assetDone[key][0]} ${key}s, `;
    }

    return loadingStr;
  }
  setForPrint() {
    if (!this.print) {
      return;
    }
    this.changeDetectorRef.markForCheck();
    setTimeout(() => {
      this.pagedService.attachRunningSection(['top-center']);

      setTimeout(() => {
        this.loadingAllDone = true;

        this.pagedService.addPolyfill();
      }, 500);
    }, 1000);
  }
}
