import { Inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { PromptDialogComponent } from './wizard/prompt-dialog/prompt-dialog.component';
import { RoutesService } from '@codenteam/core/routes';

@Injectable({
  providedIn: 'root',
})
export class AccountExceededServiceAndAccessibility {
  constructor(
    private dialogRef: MatDialog,
    private router: Router,
    private route: ActivatedRoute,

    @Inject(RoutesService) private routesService: RoutesService
  ) {}
  handelAccountExceededAndAccessibilityError(dontHaveAccess?: boolean) {
    //check for forbidden resources and navigate to home
    const currentPath = this.getUrlWithoutParams(this.router.url);
    if (dontHaveAccess !== undefined && dontHaveAccess === true) {
      if (currentPath !== '/auth/login') {
        this.router.navigate(this.routesService.login());
      }
      return;
    }
    const dialog = this.dialogRef.open(PromptDialogComponent, {
      data: {
        name: 'You have exceeded your quota limit! Upgrade quota!',
      },
    });
    dialog.afterClosed().subscribe((res) => {
      if (res === true) {
        this.router.navigate(this.routesService.settings());
      }
    });
  }
  getUrlWithoutParams(url: string) {
    const urlTree = this.router.parseUrl(url);
    urlTree.queryParams = {};
    urlTree.fragment = null;
    return urlTree.toString();
  }
}
